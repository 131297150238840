import { FC } from "react";
import { useIsDesktop } from "utils/helper-functions";
import { RightImage } from "./contextual-image.styles";
import { selectContextualImage } from "redux/presValue/presValue.selector";
import { useAppSelector } from "utils/hooks";
import { selectFormLanguage } from "redux/form/form.selector";

export const ContextualImage: FC = () => {
  const contextualImage = useAppSelector(selectContextualImage);
  const formLanguage = useAppSelector(selectFormLanguage);
  const isDesktop = useIsDesktop();

  return (
    <RightImage
      src={
        contextualImage[formLanguage][
          isDesktop ? "contextualImageDT" : "contextualImageM"
        ]
      }
      alt="Heart and Stroke Researcher"
    />
  );
};

export default ContextualImage;
