import { FC } from "react";
import { IconProps } from "utils/interfaces";

export const Payment: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.375 16.7917C19.375 23.201 24.5907 28.4167 31 28.4167C37.4092 28.4167 42.625 23.201 42.625 16.7917C42.625 10.3825 37.4092 5.16675 31 5.16675C24.5907 5.16675 19.375 10.3825 19.375 16.7917ZM51.6667 54.2501H54.25V51.6667C54.25 41.6977 46.1357 33.5834 36.1667 33.5834H25.8333C15.8617 33.5834 7.75 41.6977 7.75 51.6667V54.2501H51.6667Z"
        fill={color}
      />
    </svg>
  );
};
