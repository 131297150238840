import { FC } from "react";
import { IconProps } from "utils/interfaces";

export const User: FC<IconProps> = ({ color }) => {
  return (
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5625 11.625C10.9932 11.625 8.52916 12.6456 6.7124 14.4624C4.89564 16.2792 3.875 18.7432 3.875 21.3125V23.25H58.125V21.3125C58.125 18.7432 57.1044 16.2792 55.2876 14.4624C53.4708 12.6456 51.0068 11.625 48.4375 11.625H13.5625ZM58.125 27.125H3.875V40.6875C3.875 43.2568 4.89564 45.7208 6.7124 47.5376C8.52916 49.3544 10.9932 50.375 13.5625 50.375H48.4375C51.0068 50.375 53.4708 49.3544 55.2876 47.5376C57.1044 45.7208 58.125 43.2568 58.125 40.6875V27.125ZM40.6875 38.75H48.4375C48.9514 38.75 49.4442 38.9541 49.8075 39.3175C50.1709 39.6808 50.375 40.1736 50.375 40.6875C50.375 41.2014 50.1709 41.6942 49.8075 42.0575C49.4442 42.4209 48.9514 42.625 48.4375 42.625H40.6875C40.1736 42.625 39.6808 42.4209 39.3175 42.0575C38.9541 41.6942 38.75 41.2014 38.75 40.6875C38.75 40.1736 38.9541 39.6808 39.3175 39.3175C39.6808 38.9541 40.1736 38.75 40.6875 38.75Z"
        fill={color}
      />
    </svg>
  );
};
