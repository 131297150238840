import React, { useEffect, useState } from "react";
import {
  updatePopup,
  updateCurrentForm,
  updateSession,
} from "redux/helpers/helpers.reducer";
import {
  selectIsCheckoutCallError,
  selectPopup,
  selectSession,
} from "redux/helpers/helpers.selector";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  PopupContainer,
  Overlay,
  CloseButton,
  PopupMessage,
} from "./popup.styles";
import { FocusScope } from "react-aria";
import { useTranslation } from "react-i18next";
import { selectDonorInfo } from "redux/form/form.selector";
import { ClipLoader } from "react-spinners";
import { primaryColor, popupMessages } from "utils/variables";
import {
  calculateTimeInTheSessionLeft,
  getSessionStartTime,
} from "utils/helper-functions";

const Popup: React.FC = () => {
  // LOCAL STATE
  const [sessionTimeLeft, setSessionTimeLeft] = useState("");

  // GLOBAL STATE
  const popup = useAppSelector(selectPopup);
  const isCheckoutCallError = useAppSelector(selectIsCheckoutCallError);
  const dispatch = useAppDispatch();
  const { firstName } = useAppSelector(selectDonorInfo);
  const session = useAppSelector(selectSession);
  const { t } = useTranslation();

  const handleStayConnected = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updatePopup({ ...popup, isActive: false, isSession: false }));
    dispatch(
      updateSession({
        ...session,
        startDate: getSessionStartTime(),
        sessionMilliseconds:
          +process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS,
      }),
    );
  };

  const handleEscapeKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Escape" && !popup.isSession)
      dispatch(updatePopup({ ...popup, isActive: false }));

    if (
      popup.message === popupMessages.cantProcessRequest ||
      isCheckoutCallError
    )
      dispatch(updateCurrentForm(1));
  };

  const handleOkButtonClick = () => {
    dispatch(updatePopup({ ...popup, isActive: false }));

    if (
      popup.message === popupMessages.cantProcessRequest ||
      isCheckoutCallError
    )
      dispatch(updateCurrentForm(1));
  };

  const handlePageReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    setSessionTimeLeft(
      calculateTimeInTheSessionLeft(session.sessionMilliseconds),
    );
  }, [session]);

  return (
    <>
      {popup.isActive && (
        <div role="dialog" aria-modal={true}>
          <Overlay />
          <FocusScope contain restoreFocus autoFocus>
            <PopupContainer
              data-cy="popupContainer"
              onKeyDown={handleEscapeKeyPress}
              isError={popup.isError}
              tabIndex={popup.isLoading ? 1 : undefined}
            >
              {popup.isLoading && (
                <div style={{ margin: "20px 0", textAlign: "center" }}>
                  <ClipLoader color={primaryColor} loading={true} size={70} />
                </div>
              )}
              <PopupMessage>
                {t(popup.message, { firstName, sessionTimeLeft })}
              </PopupMessage>
              {!popup.isLoading && !popup.isSession && (
                <CloseButton onClick={handleOkButtonClick} tabIndex={1}>
                  OK
                </CloseButton>
              )}

              {popup.isSession && (
                <CloseButton
                  onClick={
                    session.sessionMilliseconds
                      ? handleStayConnected
                      : handlePageReload
                  }
                >
                  {t(session.sessionMilliseconds ? "Stay connected" : "Reload")}
                </CloseButton>
              )}
            </PopupContainer>
          </FocusScope>
        </div>
      )}
    </>
  );
};

export default Popup;
