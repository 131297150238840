import React, { useEffect, useState } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { updateFormLanguage } from "redux/form/form.reducer";
import { useAppDispatch, useAppSelector } from "utils/hooks";

import {
  RightBlockContainer,
  LeftBlockContainer,
} from "pages/donation/donation-page.styles";

import {
  ThankYouText,
  TogetherText,
  ThankYouParagraph,
  ThankYouTextContainer,
  SeeImpactLink,
  EmailLink,
  ThankYouButtonsContainer,
  DoctorNameBox,
  ConfirmationNumber,
  BannerContainer,
} from "./thank-you.styles";
import analyticsAPI from "utils/analyticsAPI";
import Footer from "components/footer/footer.component";
import { selectResearcherName } from "redux/helpers/helpers.selector";
import { selectFormLanguage } from "redux/form/form.selector";
import { PageContainer } from "global";
import ContextualImage from "components/contextual-image/contextual-image.component";

interface Props {
  viewportHeight: number;
}

const ThankYouPage: React.FC<Props> = ({ viewportHeight }) => {
  // URL parameteres
  const [searchParams] = useSearchParams();
  const donorName = searchParams.get("name");
  const formTypeURL = searchParams.get("type");
  const presValue = searchParams.get("pres");
  const source = searchParams.get("source");
  const lookupId = searchParams.get("lookupId");
  const locale = searchParams.get("locale");
  const confNumber = searchParams.get("confNumber");
  const giftType = searchParams.get("giftType");
  const cid = searchParams.get("cid");
  const province = searchParams.get("province")?.toLowerCase();

  // LOCAL STATE
  const [url, setUrl] = useState("");

  // GLOBAL STATE
  const researcherName = useAppSelector(selectResearcherName);
  const formLanguage = useAppSelector(selectFormLanguage);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Analytics
  const appInsights = useAppInsightsContext();
  const pageEngagementStartTime = Date.now();

  useEffect(() => {
    analyticsAPI.handleTrackPageView(appInsights, {
      name: "ThankYouPage",
      pageEngagementStartTime,
    });

    // Create URL with the same parameters as the donation
    setUrl(
      `${window.location.origin}?${
        formTypeURL ? "type=" + formTypeURL + "&" : ""
      }${presValue ? "pres=" + presValue + "&" : ""}${
        source ? "source=" + source + "&" : ""
      }${lookupId ? "lookupId=" + lookupId + "&" : ""}${cid ? "cid=" + cid + "&" : ""}${
        locale ? "locale=" + locale : ""
      }`,
    );
    if (locale?.toLowerCase() === "fr") {
      dispatch(updateFormLanguage("fr"));
    }
    return () => {
      const engagementTimeSeconds =
        (Date.now() - pageEngagementStartTime) / 1000;
      const metricData = {
        average: Math.round(engagementTimeSeconds * 100) / 100,
        name: "React Component Engaged Time (seconds)",
        sampleCount: 1,
      };
      const additionalProperties = { "Component Name": "ThankYouPage" };
      appInsights.trackMetric(metricData, additionalProperties);
    };
  }, []); // eslint-disable-line

  return (
    <PageContainer viewportHeight={viewportHeight}>
      {/* <ScrollToTopOnRouteChange /> */}
      <LeftBlockContainer id="left-section" isContextual={true}>
        <main>
          <ThankYouTextContainer>
            <ThankYouText>
              {donorName}, {t("thank you for gift")}!
            </ThankYouText>
            <ConfirmationNumber>
              {t("Your confirmation number")} {confNumber}
            </ConfirmationNumber>
            <TogetherText>
              {t("Together, we'll")} <span>{t("beat")}</span>{" "}
              {t("heart disease and stroke")}
            </TogetherText>
            <ThankYouParagraph>
              {t("thank you for supporting breakthrough")}
            </ThankYouParagraph>
            {giftType !== "monthly" && (
              <ThankYouParagraph>
                {t("tax receipt")}{" "}
                <EmailLink href="mailto:donorinfo@heartandstroke.ca">
                  donorinfo@heartandstroke.ca
                </EmailLink>
                .
              </ThankYouParagraph>
            )}
            <ThankYouButtonsContainer>
              <SeeImpactLink
                href={
                  formLanguage === "en"
                    ? process.env.REACT_APP_SEE_IMPACT_URL_EN
                    : process.env.REACT_APP_SEE_IMPACT_URL_FR
                }
              >
                {t("See the impact")}
              </SeeImpactLink>
              <SeeImpactLink href={url}>
                {t("Start a new donation")}
              </SeeImpactLink>
            </ThankYouButtonsContainer>
            <BannerContainer>
              <a
                href={
                  formLanguage === "en"
                    ? province === "on"
                      ? process.env
                          .REACT_APP_THANK_YOU_PAGE_BANNER_LOTTERY_LINK_EN
                      : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_EN
                    : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LINK_FR
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    formLanguage === "en"
                      ? province === "on"
                        ? process.env.REACT_APP_THANK_YOU_PAGE_BANNER_LOTTERY_EN
                        : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_EN
                      : process.env.REACT_APP_THANK_YOU_PAGE_BANNER_FR
                  }
                  alt="Ride For Heart Banner"
                />
              </a>
            </BannerContainer>
          </ThankYouTextContainer>
        </main>

        <Footer />
      </LeftBlockContainer>
      <RightBlockContainer isContextual={true}>
        <ContextualImage />
        <DoctorNameBox>
          {t("TY researcher name", { researcherName })}
        </DoctorNameBox>
      </RightBlockContainer>
    </PageContainer>
  );
};

export default ThankYouPage;
