import React, { useEffect, useRef, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from "use-places-autocomplete";
import { ErrorMessage, Input, InputContainer, RedStar } from "global";
import {
  DropDownContainer,
  DropDownListContainer,
  ListItem,
} from "./address-complete.styles";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  selectDonationInfo,
  selectDonorInfo,
  selectFormErrors,
} from "redux/form/form.selector";
import {
  updateDonationInfo,
  updateDonorInfo,
  updateHonoureeAddressLine,
} from "redux/form/form.reducer";
import lookup from "country-code-lookup";

interface Props {
  addressType: "donor" | "receiver";
  countryToLookup: string;
}

const AddressComplete: React.FC<Props> = ({ addressType, countryToLookup }) => {
  // GLOBAL STATE
  const donorInfo = useAppSelector(selectDonorInfo);
  const donationInfo = useAppSelector(selectDonationInfo);
  const formErrors = useAppSelector(selectFormErrors);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const countryLookup = lookup.byCountry(countryToLookup);
  const {
    ready,
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: [countryLookup?.internet ? countryLookup.internet : "ca"],
      },
    },
    cache: 0,
  });
  const { t } = useTranslation();

  const handleAddressInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const filteredValue = value.replace(/=/g, "");

    setValue(filteredValue);
    if (addressType === "donor") {
      dispatch(updateDonorInfo({ ...donorInfo, addressLine1: filteredValue }));
    } else if (addressType === "receiver") {
      dispatch(updateHonoureeAddressLine(filteredValue));
    }

    if (data.length !== 0) setIsDropdownActive(true);
  };

  const handleAddressSelect = async (
    structured_formatting: google.maps.places.StructuredFormatting,
    place_id: string,
  ) => {
    // Hide search options
    setIsDropdownActive(false);
    clearSuggestions();

    // Getting postal code
    const results = await getGeocode({
      placeId: place_id,
    });
    const postal = getZipCode(results[0], false) || "";

    // Get appartment number
    const appartmentNumber =
      results[0].address_components.find((a) => a.types[0] === "subpremise")
        ?.short_name || "";

    // Get street number
    const streetNumber =
      results[0].address_components.find((a) => a.types[0] === "street_number")
        ?.short_name || "";

    // Get street name
    const streetName =
      results[0].address_components.find((a) => a.types[0] === "route")
        ?.short_name || "";

    // Get province
    const provinceState =
      results[0].address_components.find(
        (a) => a.types[0] === "administrative_area_level_1",
      )?.short_name || "";

    // Get city
    const city =
      results[0].address_components.find((a) => a.types[0] === "locality")
        ?.long_name ||
      results[0].address_components.find((a) => a.types[0] === "postal_town")
        ?.long_name ||
      "";

    // Get second address
    const addressLine2 =
      countryToLookup !== "Canada"
        ? results[0].address_components.find((a) =>
            a.types.includes("sublocality_level_1"),
          )?.long_name || ""
        : "";

    // Get country
    const country =
      results[0].address_components.find((a) => a.types[0] === "country")
        ?.long_name || "Canada";

    if (addressType === "donor") {
      dispatch(
        updateDonorInfo({
          ...donorInfo,
          addressLine1: appartmentNumber
            ? `${appartmentNumber + "-"}${streetNumber} ${streetName}`
            : structured_formatting.main_text,
          city,
          provinceState,
          country,
          postal,
          addressLine2,
        }),
      );
    } else if (addressType === "receiver") {
      dispatch(
        updateDonationInfo({
          ...donationInfo,
          honoureeAddressLine: appartmentNumber
            ? `${appartmentNumber + "-"}${streetNumber} ${streetName}`
            : structured_formatting.main_text,
          honoureeCity: city,
          honoureeProvinceState: provinceState,
          honoureeCountry: country,
          honoureePostal: postal,
        }),
      );
    }
  };

  useEffect(() => {
    // Function to handle clicks outside the component
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsDropdownActive(false);
      }
    };

    // Attach the event listener to the whole document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropDownContainer>
      <InputContainer>
        <label
          htmlFor={
            addressType === "donor" ? "addressLine1" : "honoureeAddressLine"
          }
        >
          <RedStar>*</RedStar>{" "}
          {addressType === "donor"
            ? t("Street Address")
            : t("Honouree Street Address")}
          :
        </label>
        <Input
          type="text"
          name={
            addressType === "donor" ? "addressLine1" : "honoureeAddressLine"
          }
          id={addressType === "donor" ? "addressLine1" : "honoureeAddressLine"}
          onChange={handleAddressInput}
          disabled={!ready}
          value={
            addressType === "donor"
              ? donorInfo.addressLine1
              : donationInfo.honoureeAddressLine
          }
          isError={
            addressType === "donor"
              ? formErrors.addressLine1
              : formErrors.honoureeAddressLine
          }
          maxLength={200}
        />
        {isDropdownActive && (
          <DropDownListContainer ref={ref} data-cy="addressList">
            {data.map(({ place_id, description, structured_formatting }) => (
              <ListItem
                data-cy="addressListItem"
                onClick={() =>
                  handleAddressSelect(structured_formatting, place_id)
                }
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.code === "Space") {
                    e.preventDefault();
                    handleAddressSelect(structured_formatting, place_id);
                  }
                }}
                key={place_id}
                tabIndex={0}
              >
                {description}
              </ListItem>
            ))}
          </DropDownListContainer>
        )}
        {addressType === "donor"
          ? formErrors.addressLine1 && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.addressLine1)}
              </ErrorMessage>
            )
          : formErrors.honoureeAddressLine && (
              <ErrorMessage>
                <span>!</span> {t(formErrors.honoureeAddressLine)}
              </ErrorMessage>
            )}
      </InputContainer>
    </DropDownContainer>
  );
};

export default AddressComplete;
