import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { TFunction } from "i18next";
import { FormErrors } from "./interfaces";

class AnalyticsAPI {
  // NAVIGATION ANALYTICS
  handleNavigationButton(
    appInsights: ReactPlugin,
    parameters: { buttonID: number; buttonText: string },
  ) {
    appInsights.trackEvent({
      name: "Tab navigate",
      properties: { index: parameters.buttonID, button: parameters.buttonText },
    });
  }

  handleBackButton(
    appInsights: ReactPlugin,
    parameters: { currentForm: number },
  ) {
    appInsights.trackEvent({
      name: "Back button",
      properties: { currentForm: parameters.currentForm },
    });
  }

  handleNextStep(
    appInsights: ReactPlugin,
    parameters: { currentForm: number },
  ) {
    appInsights.trackEvent({
      name: "Next step",
      properties: { currentForm: parameters.currentForm },
    });
  }

  // DONATION TYPE ANALYTICS
  handleOtherSelected(
    appInsights: ReactPlugin,
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    appInsights.trackEvent({
      name: "Other amount select",
      properties: { amount: e.currentTarget.value },
    });
  }

  handleAskSelect(
    appInsights: ReactPlugin,
    e: React.MouseEvent<HTMLButtonElement>,
  ) {
    appInsights.trackEvent({
      name: "Ask select",
      properties: {
        index: e.currentTarget.dataset.id,
        amount: e.currentTarget.value,
      },
    });
  }

  // PAYMENT ANALYTICS
  handleSuccessfulPayment(
    appInsights: ReactPlugin,
    parameters: {
      name: string;
      donationAmount: number;
      donationType: "monthly" | "one-time" | undefined;
      ecardId: number;
      paymentId: string;
      isCoverFees: boolean;
      honourType: string;
      honourCardType: string;
      isCompanyGift: boolean;
    },
  ) {
    appInsights.trackEvent({
      name: parameters.name,
      properties: {
        amount: parameters.donationAmount,
        donationType: parameters.donationType,
        ecardId: parameters.ecardId,
      },
    });

    window.dataLayer.push({
      event: "purchase",
      transactionId: parameters.paymentId,
      revenue: parameters.donationAmount.toFixed(2),
      donationFrequency: parameters.donationType,
      donorCoveringFees: parameters.isCoverFees ? "yes" : "no",
      donationType: parameters.honourType ? parameters.honourType : "standard",
      cardType: parameters.honourCardType,
      isCompanyGift: parameters.isCompanyGift ? "yes" : "no",
    });
  }

  // TRACK PAGE VIEW
  handleTrackPageView(
    appInsights: ReactPlugin,
    parameters: { name: string; pageEngagementStartTime: number },
  ) {
    appInsights.trackPageView({
      name: parameters.name,
      properties: { duration: Date.now() - parameters.pageEngagementStartTime },
    });
  }

  handleCurrentFormChange(parameters: {
    currentForm: number;
    stepName: string;
  }) {
    window.dataLayer.push({
      event: "flowStep",
      stepNumber: parameters.currentForm + 1,
      stepName: parameters.stepName,
    });
  }

  handleErrors(formErrors: FormErrors, appInsights: ReactPlugin, t: TFunction) {
    const formErrorsArray = Object.values(formErrors);
    const formErrorsFullNames = formErrorsArray.map((error) => t(error));

    window.dataLayer.push({
      event: "validationError",
      errorMessages: formErrorsFullNames,
    });
    appInsights.trackEvent({
      name: "Form error",
      properties: { errors: formErrors },
    });
  }

  handleRecaptcha(appInsights: ReactPlugin, token: string, score: number) {
    appInsights.trackEvent({
      name: "Recaptcha score",
      properties: {
        token,
        score,
      },
    });
  }
}

export default new AnalyticsAPI();
