import React, { FC } from "react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { ButtonContainer } from "./navigation-button.styles";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import {
  selectDonorInfo,
  selectIsCompanyGift,
  selectDonationInfo,
  selectLoyaltyCardNumber,
  selectCallCentreNumber,
} from "redux/form/form.selector";
import {
  selectCurrentForm,
  selectPopup,
  selectPresValue,
} from "redux/helpers/helpers.selector";
import { updateFormErrors } from "redux/form/form.reducer";
import { updateCurrentForm, updatePopup } from "redux/helpers/helpers.reducer";
import { scrollToTheError, validate } from "utils/helper-functions";
import { IconProps, ValidationErrors } from "utils/interfaces";

import { schemaConstructor } from "utils/validation-schemas";
import { useTranslation } from "react-i18next";
import analyticsAPI from "utils/analyticsAPI";
import { popupMessages, primaryColor } from "utils/variables";
import { CountryCode } from "libphonenumber-js";
import lookup from "country-code-lookup";
import { ValidationError } from "yup";

interface Props {
  buttonText: string;
  buttonID: number;
  Icon: FC<IconProps>;
}

const NavigationButton: React.FC<Props> = ({ buttonText, buttonID, Icon }) => {
  const currentForm = useAppSelector(selectCurrentForm);
  const donorInfo = useAppSelector(selectDonorInfo);
  const isCompanyGift = useAppSelector(selectIsCompanyGift);
  const donationInfo = useAppSelector(selectDonationInfo);
  const loyaltyCardNumber = useAppSelector(selectLoyaltyCardNumber);
  const callCentreNumber = useAppSelector(selectCallCentreNumber);
  const presValue = useAppSelector(selectPresValue);
  const popup = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();

  // LOCAL STATE
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  const handleNextForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    analyticsAPI.handleNavigationButton(appInsights, { buttonID, buttonText });
    if (buttonID === currentForm) {
      return;
    } else if (buttonID < currentForm) {
      dispatch(updateCurrentForm(buttonID));
      return;
    }

    const donationSchema = schemaConstructor("donationType", {
      loyaltyCardNumber,
    });
    const donorSchema = schemaConstructor("donorInfo", {
      loyaltyCardNumber,
      locale: lookup.byCountry(donorInfo.country)?.iso2 as CountryCode,
      presValue,
    });

    if (currentForm === 0) {
      try {
        await validate(
          {
            donationAmount: donationInfo.donationAmount,
            donationType: donationInfo.donationType,
            honourType: donationInfo.honourType,
            honoureeName: donationInfo.honoureeName,
            recipientEmail: donationInfo.recipientEmail,
            emailSubject: donationInfo.emailSubject,
            cardActive: donationInfo.cardActive,
            honoureeAddressLine: donationInfo.honoureeAddressLine,
            honoureeCity: donationInfo.honoureeCity,
            honoureeProvinceState: donationInfo.honoureeProvinceState,
            honoureeCountry: donationInfo.honoureeCountry,
            honoureePostal: donationInfo.honoureePostal,
            cardRecipientFullName: donationInfo.cardRecipientFullName,
            isTributeGift: donationInfo.isTributeGift,
            honourCardType: donationInfo.honourCardType,
            loyaltyCardNumber: donationInfo.loyaltyCardNumber,
          },
          donationSchema,
        );

        if (buttonID === 1) {
          dispatch(updateCurrentForm(1));
          dispatch(updateFormErrors({}));
        } else if (buttonID === 2) {
          try {
            await validate(
              {
                ...donorInfo,
                loyaltyCardNumber,
                isCompanyGift,
                callCentreNumber,
              },
              donorSchema,
            );
            dispatch(updateCurrentForm(2));
            dispatch(updateFormErrors({}));
          } catch (error: unknown) {
            const errors: ValidationErrors = {};

            (error as ValidationError).inner.forEach((err) => {
              if (err.path) {
                errors[err.path] = err.message;
              }
            });

            dispatch(updateCurrentForm(1));
            dispatch(updateFormErrors(errors));
            dispatch(
              updatePopup({
                ...popup,
                isActive: true,
                message: popupMessages.errorsFixed,
                isError: true,
                isLoading: false,
              }),
            );
          }
        }
      } catch (error: unknown) {
        const errors: ValidationErrors = {};

        (error as ValidationError).inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        scrollToTheError(Object.keys(errors)[0]);

        dispatch(updateFormErrors(errors));
        dispatch(
          updatePopup({
            ...popup,
            isActive: true,
            message: popupMessages.errorsFixed,
            isError: true,
            isLoading: false,
          }),
        );
      }
    } else if (currentForm === 1) {
      try {
        // Validate
        await validate(
          { ...donorInfo, loyaltyCardNumber, isCompanyGift, callCentreNumber },
          donorSchema,
        );
        dispatch(updateCurrentForm(2));
        dispatch(updateFormErrors({}));
      } catch (error: unknown) {
        const errors: ValidationErrors = {};

        (error as ValidationError).inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        scrollToTheError(Object.keys(errors)[0]);

        dispatch(updateFormErrors(errors));
        dispatch(
          updatePopup({
            ...popup,
            isActive: true,
            message: popupMessages.errorsFixed,
            isError: true,
            isLoading: false,
          }),
        );
      }
    }
  };

  return (
    <ButtonContainer
      isCurrentOrPastForm={currentForm >= buttonID}
      onClick={handleNextForm}
      data-cy={buttonText}
    >
      <Icon color={currentForm >= buttonID ? primaryColor : "#000"} />
      <span>{t(buttonText)}</span>
    </ButtonContainer>
  );
};

export default NavigationButton;
