import { outlineColor, primaryColor } from "utils/variables";
import styled from "styled-components";

interface Props {
  isError?: string;
}

export const AeroplanContainer = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #333;
`;

export const AeroplanText = styled.p`
  font-size: 20px;
  color: #333;

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }
`;

export const AeroplanLabel = styled.label`
  font-size: 20px;
  color: #333;

  @supports not (gap: 10px) {
    margin: 0 20px;

    @media only screen and (max-width: 420px) {
      margin: 20px 10px;
    }
  }
`;

export const LoyaltyPointsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @supports (gap: 10px) {
    gap: 20px;

    @media only screen and (max-width: 420px) {
      gap: 20px 10px;
    }
  }

  @media only screen and (max-width: 320px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const AeroplanLogo = styled.img`
  display: block;
  max-width: 130px;
  height: 100%;
  margin-right: 20px;
`;

export const AeroplanInput = styled.input`
  width: 150px;
  height: 41px;
  padding: 0 7px;
  font-size: 20px;

  ${(props: Props) => props.isError && `border: 1px solid ${primaryColor};`}

  &:focus {
    outline: solid ${outlineColor} 2px;
  }

  @supports not (gap: 10px) {
    margin-right: 20px;

    @media only screen and (max-width: 420px) {
      margin: 20px 10px 0 0;
    }
  }

  @media only screen and (max-width: 550px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 420px) {
    margin: 0 15px 0 0;
  }
`;
