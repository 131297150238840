import * as yup from "yup";
import "yup-phone-lite";
import { CountryCode } from "libphonenumber-js";
import { nameRegex } from "./variables";

export const schemaConstructor = (
  schemaType: string,
  options: {
    loyaltyCardNumber?: string;
    locale?: CountryCode;
    presValue?: string;
  } = {},
) => {
  if (schemaType === "donationType") {
    return {
      donationType: yup.string().required("Select gift type"),
      donationAmount: yup.number().moreThan(4.99, "More than 5"),
      honourType: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().required("Select honour gift type"),
      }),
      honoureeName: yup
        .string()
        .trim()
        .when("isTributeGift", {
          is: true,
          then: yup.string().required("Enter honouree name"),
        }),
      recipientEmail: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "ecard",
          then: yup
            .string()
            .trim()
            .email("Provide valid email")
            .required("Enter recipient email"),
        }),
      }),
      emailSubject: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "ecard",
          then: yup.string().trim().required("Provide ecard subject"),
        }),
      }),
      cardRecipientFullName: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter full name"),
        }),
      }),
      honoureeAddressLine: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter street address"),
        }),
      }),
      honoureeCity: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter city"),
        }),
      }),
      honoureePostal: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Enter postal"),
        }),
      }),
      honoureeProvinceState: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Select State / Province"),
        }),
      }),
      honoureeCountry: yup.string().when("isTributeGift", {
        is: true,
        then: yup.string().when("honourCardType", {
          is: "mail",
          then: yup.string().trim().required("Select country"),
        }),
      }),
      cardActive: yup.number().when("isTributeGift", {
        is: true,
        then: yup.number().when("honourCardType", {
          is: (val: string) => val === "mail" || val === "ecard",
          then: yup.number().moreThan(0, "Select card"),
        }),
      }),
      isTributeGift: yup.boolean(),
      honourCardType: yup.string(),
      loyaltyCardNumber: options.loyaltyCardNumber
        ? yup.string().length(9, "Loyalty card equal 9")
        : yup.string(),
    };
  } else if (schemaType === "donorInfo") {
    return {
      companyName: yup.string().when("isCompanyGift", {
        is: true,
        then: yup.string().trim().required("Enter company name"),
      }),
      firstName: yup
        .string()
        .trim()
        .required("Enter first name")
        .matches(nameRegex, "Enter first name")
        .min(2, "First name greater than 2")
        .max(50, "First name less than 50"),
      lastName: yup
        .string()
        .trim()
        .required("Enter last name")
        .matches(nameRegex, "Enter last name")
        .min(2, "Last name greater than 2")
        .max(100, "Last name less than 50"),
      addressLine1: yup.string().trim().required("Enter street address"),
      city: yup.string().trim().required("Enter city"),
      provinceState: yup.string().trim().required("Select State / Province"),
      postal: yup.string().trim().required("Enter postal"),
      country: yup.string().trim().required("Select country"),
      email: yup
        .string()
        .trim()
        .min(5, "Please provide email that is more than 5 characters")
        .max(150)
        .required("Enter email")
        .email("Provide valid email"),
      loyaltyCardNumber: options.loyaltyCardNumber
        ? yup.string().length(9, "Loyalty card equal 9")
        : yup.string(),
      phoneNumber: yup.string().test({
        name: "phone",
        test: function (value: string | undefined) {
          if (value !== "") {
            return (
              yup
                .string()
                .phone(options.locale)
                // .phone(["US", "CA", "GB", "AE", "FR"])
                .isValidSync(value)
            );
          }
          return true; // Skip validation if value is empty
        },
        message: "Invalid phone number",
      }),
      callCentreNumber:
        options.presValue === "CC" &&
        yup.string().trim().required("Enter Call Centre Call Number"),
      isCompanyGift: yup.boolean(),
    };
  } else if (schemaType === "debit") {
    return {
      institutionNumber: yup.string().min(3, "Institution number 3 digits"),
      transitNumber: yup.string().min(5, "Transit number 5 digits"),
      accountNumber: yup.string().min(5, "Account number 5 digits"),
      verifyAccountNumber: yup
        .string()
        .min(5, "Verified account number 5 digits")
        .test(
          "accounts-match",
          "Account numbers do not match",
          function (value) {
            return value === this.resolve(yup.ref("accountNumber"));
          },
        ),
    };
  }
};
